<template>
	<div class="card blog-card transition-fast" style="display: flex">
		<a :href="'/blog/' + post.slug">
			<div class="card-image">
				<figure class="image">
					<img
						:src="post.post_thumbnail.URL"
						:alt="post.title"
						class="card-feature-img transition-fast"
					/>
				</figure>
			</div>
			<div class="card-content">
				<h3 class="title is-size-5">
					<v-clamp autoresize :max-lines="3">{{
						cleanText(post.title)
					}}</v-clamp>
				</h3>
				<div class="content excerpt">
					<v-clamp autoresize :max-lines="4">{{
						cleanText(post.excerpt)
					}}</v-clamp>
				</div>
				<div class="bottom-text">
					<div class="is-pulled-left">
						<span>{{ $t("blog_card_by") + formatName(post.author) }}</span>
					</div>
					<div class="is-pulled-right">
						<span class="has-text-right">
							<time :datetime="post.date.substring(0, 10)">{{
								new Date(post.date).toLocaleDateString()
							}}</time>
						</span>
					</div>
				</div>
			</div>
		</a>
	</div>
</template>

<script>
import VClamp from "vue-clamp";

export default {
	name: "BlogCard",
	components: {
		VClamp,
	},
	props: {
		post: {
			type: Object,
			required: true,
		},
	},
	data() {
		return {
			cookieConsent: false,
		};
	},
	methods: {
		formatName(author) {
			return author.first_name + " " + Array.from(author.last_name)[0] + ".";
		},
	},
};
</script>

<style scoped>
.blog-card {
	display: flex;
	flex-direction: column;
	height: 100%;
	box-shadow: 0px 5px 25px rgba(0, 0, 0, 0.08);
	border-radius: 10px;
	max-width: 280px;
	min-height: 435px;
	margin: 0 auto;
}
a {
	color: inherit;
	transition-delay: 0s;
	transition-duration: 0.35s;
	transition-property: all;
	transition-timing-function: cubic-bezier(0.25, 0.46, 0.45, 0.94);
}
a:hover .title {
	color: #228cdb;
	transition-delay: 0s;
	transition-duration: 0.2s;
	transition-property: all;
	transition-timing-function: cubic-bezier(0.25, 0.46, 0.45, 0.94);
}
.card-feature-img {
	height: 160px;
	object-fit: cover;
	border-radius: 10px 10px 0px 0px;
}
.blog-card:hover .card-feature-img {
	opacity: 0.8;
}
.card-content h3.title.is-size-5 {
	margin-top: -5px;
	line-height: 1.3;
}
.card-content .excerpt {
	margin-top: -10px;
}
.card-content .bottom-text {
	border-top: 1.5px solid #ebebeb;
	padding-top: 10px;
	position: absolute;
	bottom: 20px;
	width: calc(100% - 44px);
}
@media only screen and (max-width: 768px) {
	.blog-card {
		max-width: inherit;
	}
	.card-feature-img {
		height: 180px;
	}
}
</style>
